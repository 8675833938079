<template>
  <div>
    <v-btn
        small
        outlined
        to="/mechanic"
        sele
        class="mt-4 mb-4"
        color="blue"
        :exact="true"
    >
      К списку поверок
    </v-btn>

    <heading
        :title="(id ? 'Редактирование' : 'Добавление') + ' поверки'"
    />

    <verification-form
        :id="id"
    />
  </div>
</template>

<script>
import VerificationForm from "@/components/Verifications/Form"

export default {
  components: {
    VerificationForm
  },

  props: {
    id: {
      default: false
    }
  }
}
</script>
